import React from 'react';
import { AutoComplete } from '../../../../components/index';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setProjectPanel } from '../../../../redux/reducer/appSlice';

const PanelDropdown = (props) => {
    const BUSINESS_NAME = localStorage.getItem("businessName");
    const dispatch = useDispatch();
    const projectList = useSelector((state) => state.overviewReducer.projectList);
    const projectPanel = useSelector((state) => state.appReducer.projectPanel);
    const navigate = useNavigate();

    const panelOptionsList = projectList.data && projectList.data.length && projectList.data.map(el => {
        return { label: el.siteName, value: el.pEntityId }
    });

    const onChangeHandler = (e, option) => {
        dispatch(setProjectPanel(option));
        navigate("/dashboard", { state: { id: option.value } });
    }
    return (
        <div className="panel-dropdown-section">
            <strong>{BUSINESS_NAME}</strong>
        </div>
    )
}

export default PanelDropdown;