import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { default as Table } from "mui-datatables";
import { isArray, isObject } from '../../Utils/commonUtils';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import MUIHelper from '../MUIDataTable/MUIHelper'
import { Loader } from '../index'
import './MUIDataTable.scss';

function MUIDataTable({ tableClassName, title, data, columns, options, loading, appliedFilterList, closeChipHandler }) {
  const MUITheme = () =>
    createTheme({
      typography: {
        fontFamily: 'Rubik',
        fontWeight:"bold",
      },
      components: {
        MUIDataTableToolbar: {
          styleOverrides: {
            filterPaper: {
              width: "450px",
            },
          },
        },
        MUIDataTableFilter: {
          styleOverrides: {
            resetLink: {
              display: "none"
            }
          }
        },
      },

    });
  // console.log(data, "data muiDattable")

  const CustomChip = ({ label, value,inputType,index }) => {

    if(inputType==="date"){
      value = value.split("T")[0];
    }
    return (
      <>
        <div className="filter-chip">
          <div>
            <p className="column-label">{label}</p>
            <p className="column-value">{value}</p>
          </div>
          <IconButton className="close-icon" onClick={() => { closeChipHandler(index) }}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      </>
    );
  };

  const CustomFilterList = (props) => {
    let formattedList = [];
     columns && columns.length > 0 && appliedFilterList && appliedFilterList.length > 0 && appliedFilterList.forEach((item, index) => {
      if (item.length > 0) {
        if (isArray(item)) {
          if (isArray(item[0])) {
            console.log('array')
           formattedList.push({ label: columns[index].label, value: item[0],inputType: columns[index].options.inputType, index })
          } else if (isObject(item[0])) {
             console.log('obj');
            formattedList.push({ label: columns[index].label, value: item[0].label,inputType: columns[index].options.inputType, index })
          } else {
            console.log('for text')
            formattedList.push({ label: columns[index].label, value: item[0], inputType: columns[index].options.inputType,index })
          }
        } else {
          formattedList.push({ label: columns[index].label, value: item[0], inputType: columns[index].options.inputType, index })
        }
      }
    // console.log("formattedList ::::",formattedList)
    });
    return (
      <div className="filter-chip-wrapper">
        { formattedList && formattedList.length > 0 && formattedList.map((el, i) => {
          return <CustomChip key={el.label + i} {...el} />
        })}
      </div>
    )
  };
  return (
    <div className={`mui-table ${tableClassName}`}>
      {loading && <Loader pageLoader={true} />}
      <ThemeProvider theme={MUITheme}>
        <Table
          title={title}
          data={data}
          columns={columns}
          options={options}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />
      </ThemeProvider>
    </div>
  )
}

export default MUIDataTable;
