import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosPrService } from '../../../axios/axiosInstance';
import { showSnackbar } from '../../../redux/reducer/appSlice';
import { isArray } from '../../../Utils/commonUtils';
import { FLOW_TYPE } from '../../../Utils/constants';

export const fetchOrderList = createAsyncThunk('/fetchManageOrderList', async ({ data, params }, { dispatch }) => {
  params.page = params.page + 1;
  let result = { total: 0, data: [] }
  return await axiosPrService.post('/master/search/order', data, { params }, { dispatch })
    .then(res => {
      if (res.status === 200 && isArray(res.data)) {
        console.log(res.data);
        for (var i = 0; i < res.data.length; i++) {
            res.data[i].orderDate = res.data[i].orderDate.split("T")[0];
            if(res.data[i].assignedToUser!=null){
              res.data[i].assignedToUser= res.data[i].assignedToUser.userName;
            }else{
              res.data[i].assignedToUser= "Not Assigned"              
            }

            // if(res.data[i].deliveryAddress!=null){
            //   let address = '';
            //   address = res.data[i].deliveryAddress.address ? res.data[i].deliveryAddress.address + "," : null;
            //   address = address + ( res.data[i].deliveryAddress.city !==null && res.data[i].deliveryAddress.city!=="" ? res.data[i].deliveryAddress.city + "," : '');
            //   address = address + (res.data[i].deliveryAddress.sate !==null && res.data[i].deliveryAddress.state!=="" ? res.data[i].deliveryAddress.state + "," : '');
            //   address = address +  (res.data[i].deliveryAddress.pinCode !==null && res.data[i].deliveryAddress.pinCode!=="" ? res.data[i].deliveryAddress.pinCode : '');
            //   res.data[i].deliveryAddress= address;//res.data[i].deliveryAddress.address;
            // }else{
            //   res.data[i].deliveryAddress= "--";            
            // }

        }
        result = { total: Number(res.headers['x-total-records']), data: res.data }
        return result;
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Service unavailable" }));
        return result;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return result;
      }
    });
});

export const fetchOrderListTableColumnConfig = createAsyncThunk('/fetchOrderTableConfig', async ({ params }, { dispatch }) => {
  let result = [];
  return await axiosPrService.get('/configuration/order', { params }, { dispatch })
    .then(res => {
      if (res.status === 200 && isArray(res.data)) {
       // console.log("configuration order :::: ",res.data)
        let result = res.data[0].fields;
        console.log(result);
        return result
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Service unavailable" }));
        return result;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return result;
      }
    });
});



const initialState = {
  // table 
  data: [],
  loading: false,
  error: null,

  // columns 
  columnsOriginalResponse: [],
  columnsError: null,

  page: 0,
  total: 0,
  rowsPerPage: 10,
  sortBy: "",
  sortKey: "",
  appliedFilterList: [],

  flow: FLOW_TYPE.NEW,
  selectedOrderData: null,
};

export const manageOrderTableReducer = createSlice({
  name: 'manageOrderTableReducer',
  initialState,
  reducers: {
    updateTableState(state, { payload }) {
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
  },
  extraReducers: {
    [fetchOrderList.pending]: (state, action) => {
      state.loading = true
    },
    [fetchOrderList.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload?.data || [];
      state.total = action.payload?.total || 0;
    },
    [fetchOrderList.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    },

    [fetchOrderListTableColumnConfig.pending]: (state, action) => {
      state.loading = true
    },
    [fetchOrderListTableColumnConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.columnsOriginalResponse = action.payload
    },
    [fetchOrderListTableColumnConfig.rejected]: (state, action) => {
      state.loading = false;
      state.columnsOriginalResponse = [];
      state.columnsError = true;
    },
  }
});

export const { updateTableState } = manageOrderTableReducer.actions;

export default manageOrderTableReducer.reducer;
