import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Loader } from '../../components/index';
import { useSelector, useDispatch } from 'react-redux';
import { AWS_URL } from '../../Utils/constants';
import { fetchProductByOrderId } from '../Order/CreateOrder/CreateOrderApi';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import noimage from '../../assets/images/no_image.jpg';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import { Button, Typography } from "@mui/material";
import { fetchStore } from '../Store/EditStore/EditStoreApi';
import { fetchTax } from '../Tax/EditTax/EditTaxApi';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import './PrintOrder.scss'
import { useLocation } from 'react-router-dom';
import { each } from 'lodash';

const STORE_ID = localStorage.getItem('storeId');
console.log('sotre id :::', STORE_ID);
function PrintOrder() {
  const dispatch = useDispatch();
  const flow = useSelector((state) => state.manageOrderTableReducer.flow);
  const selectedOrderData = useSelector((state) => state.manageOrderTableReducer.selectedOrderData);
  // console.log("selectedOrderData", selectedOrderData);
  const [selProductList, setSelProductList] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  const [store, setStore] = useState([]);
  const [tax, setTax] = useState([]);
  const [totalTax, setTotalTax] = useState('');
  const [businessState, setBusinessState] = useState('');
  // const [totalPrice, setTotalPrice] = useState('');
  const [taxOption, setTaxOption] = useState('');
  const [totalGstAmt, setTotalGstAmt] = useState('');
  const [taxblAmt, setTaxblAmt] = useState('');
  const [snCGST, setSnCGST] = useState('');
  const [qty, setQty] = React.useState(null);
  const [contentHeight, setContentHeight] = useState(0);
  const location = useLocation();
  //console.log("location Data", location);
  const data = location.state;
  // console.log("selected row Data", data);

  const selectedData = JSON.parse(window.localStorage.getItem('rowData'));
  console.log("dataaaa::::", selectedData);
  console.log("Taxx::", tax)

  useEffect(() => {
    //  var totalPrice = [];
    console.log("USE EFECT Called")
    const getStoreandTaxDetails = async () => {

      // tax details
      let response = null;
      let tTax = '';
      let tOption = '';
      response = await fetchTax({ dispatch });
      if (response !== undefined && response.length > 0) {
        console.log("fetchTax response ::::: => " + JSON.stringify(response));
        setTax(response);
        response.map((tx, i) => (
          tTax = tx.taxRate,
          tOption = tx.taxOption
        ))
        setTotalTax(tTax);
        setTaxOption(tOption);
        console.log('total tax:::::::', tTax)
        console.log('tax tOption:::::::', tOption)
      }
      // store details
      let getStore = [];
      getStore = await fetchStore({}, dispatch);
      console.log("store response ::::: => " + JSON.stringify(getStore));
      //console.log("state response ::::: => " +getStore.businessState);
      setStore(getStore);
      let state = '';
      getStore.map((el, i) => (
        state = el.businessState
      ))
      setBusinessState(state);
      console.log('state::::::', state)

      // product details
      let tempProducts = [];
      let processed = [];
      let num = [];
      let eachTax = [];

      tempProducts = await fetchProductByOrderId({ orderId: selectedData.orderId }, dispatch);
      console.log("Value details:::", tempProducts)
      if (tempProducts.length > 0) {
        tempProducts.map((item) => {
          let tmpProduct = {};
          console.log("tmpProduct:::", tmpProduct);
          tmpProduct.productId = item.productId;
          tmpProduct.qty = item.quantity;
          tmpProduct.afterDiscount = item.price;
          tmpProduct.image = AWS_URL + "/" + STORE_ID + "/" + item.product.thumbnailPath;
          tmpProduct.productName = item.product.productName;
          tmpProduct.discount = item.product.discount;
          tmpProduct.price = item.product.price;
          tmpProduct.totalPrice = item.totalPrice;
          tmpProduct.tax = item.product.tax;
          tmpProduct.unitWeight = item.product.unitWeight;
          tmpProduct.gstTaxAmt = taxOption === 'allproduct' ? (item.product.price * parseFloat(tTax) / 100) : (item.product.price * parseFloat(tmpProduct.tax) / 100);
          tmpProduct.taxbleAmt = item.product.price - parseFloat(tmpProduct.gstTaxAmt);
          processed.push(tmpProduct);
          num.push(tmpProduct.gstTaxAmt);
          eachTax.push(tmpProduct.tax);
          console.log("tmpProduct.tax::", tmpProduct.tax)
          console.log("tmpProduct.tax::", tmpProduct.gstTaxAmt)
        });
        setSelProductList(processed);

        let count = 0;
        processed.map((q) => (
          count = parseInt(q.qty) + parseInt(count)
        ))
        setQty(count);
        const sum = num.reduce((a, b) => a + parseFloat(b), 0)
          console.log("sum value:::", sum);
        let gst = sum / 2;
        console.log("half gst value:::", gst);
        console.log("Toption::", tOption)
        console.log("address  ::::", selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null && selectedData.deliveryAddress.state, state, tOption);
        // if (selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null && selectedData.deliveryAddress.state.toLowerCase() === state.toLowerCase() && tOption === 'allproduct') {
        //   setSnCGST(gst);
        // }
        // else if (selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null && selectedData.deliveryAddress.state.toLowerCase() === state.toLowerCase() && tOption === 'included') {
        //   setSnCGST(gst);
        // } else {
        //   setSnCGST(sum);
        // }
        if(selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null){
          if (selectedData.deliveryAddress.state.toLowerCase() === state.toLowerCase() && tOption === 'allproduct') {
            setSnCGST(gst);
          }
          else if (selectedData.deliveryAddress.state.toLowerCase() === state.toLowerCase() && tOption === 'included') {
            setSnCGST(gst);
          }else{
            setSnCGST(sum);
          }
        }else{
          setSnCGST(gst);
        }
      }
    }

    getStoreandTaxDetails();
  }, [taxOption]);
  console.log("Product list value:::", selProductList);

  useEffect(() => {
    const invoiceContent = document.getElementById('invoiceContent');
    if (invoiceContent) {
      const height = invoiceContent.offsetHeight;
      setContentHeight(height);
    }
  }, []);


  return (
    <>
      <div className='printOrder-page'>
        <Grid item xs={12} container>
          <Grid item xs={12} align='end' id='not-print' px={5} pt={3}>
            <IconButton aria-label="print" onClick={() => window.print()}>
              <PrintIcon />
            </IconButton>
          </Grid>

          {pageLoader && <Loader pageLoader={true} pageLoaderCustomStyles={{ margin: "-20px" }} />}

          {store.map(item => (
            (item.printOption === "A4" || item.printOption === null) ?

              <Grid item xs={12} container sx={{ justifyContent: "center" }}>
                {store !== null && store !== undefined && store.map((st, i) =>
                (
                  <>
                    {st.invIsTaxInvoice === false ?
                      (<table className='table-size table-border'>
                        <tbody key={i}>
                          <tr>
                            <td colSpan="8" align='center'>
                              {st.businessName} <br></br>
                              {st.address1} <br></br>
                              {st.businessState + ', '}{st.businessCountry} <br></br>
                              {st.businessPinCode} <br></br>
                              {st.phone}
                            </td>
                          </tr>
                          <tr style={{ 'backgroundColor': '#D3D3D3' }}>
                            <td colSpan="8" align='center'>INVOICE</td>
                          </tr>
                          <tr>
                            <td colSpan="4" style={{ width: '50%' }}>
                              Billing Address <br></br> <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.address !== null && selectedData.deliveryAddress.address} <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.city !== null && selectedData.deliveryAddress.city + ', '}{selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null && selectedData.deliveryAddress.state + ', '} <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.country !== null && selectedData.deliveryAddress.country + ', '}{selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.pinCode !== null && selectedData.deliveryAddress.pinCode} <br></br>

                            </td>
                            <td colSpan="4" style={{ width: '50%' }}>
                              Shipping Address <br></br> <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.address !== null && selectedData.deliveryAddress.address} <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.city !== null && selectedData.deliveryAddress.city + ', '}{selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null && selectedData.deliveryAddress.state + ', '} <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.country !== null && selectedData.deliveryAddress.country + ', '}{selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.pinCode !== null && selectedData.deliveryAddress.pinCode} <br></br>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" style={{ width: '25%' }}>Invoice Number</td>
                            <td colSpan="2" style={{ width: '25%' }}>{selectedData.orderNum}</td>
                            <td colSpan="2" style={{ width: '25%' }}>Place of Supply</td>
                            <td colSpan="2" style={{ width: '25%' }}>{selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state}</td>
                          </tr>
                          <tr>
                            <td colSpan="2" style={{ width: '25%' }}>Invoice Date</td>
                            <td colSpan="2" style={{ width: '25%' }}>{selectedData.orderDate !== null && selectedData.orderDate}</td>
                            <td colSpan="2" style={{ width: '25%' }}>Created By</td>
                            <td colSpan="2" style={{ width: '25%' }}>System Generated</td>
                          </tr>
                          <tr style={{ 'backgroundColor': '#D3D3D3' }}>
                            <th colSpan="2" style={{ width: '25%' }}>Product Name</th>
                            <th colSpan="2" style={{ width: '25%' }}>RATE</th>
                            <th colSpan="2" style={{ width: '25%' }}>Qty</th>
                            <th colSpan="2" style={{ width: '25%' }}>Total</th>
                          </tr>
                          {selProductList.map((product, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td colSpan="2" style={{ width: '25%' }}>{product.productName}</td>
                                  <td align='center' colSpan="2" style={{ width: '25%' }}>{Number(product.price).toFixed(2)}</td>
                                  <td align='center' colSpan="2" style={{ width: '25%' }}>{product.qty !== undefined && product.qty !== null && product.qty}</td>
                                  <td align='right' colSpan="2" style={{ width: '25%' }}>{Number(product.totalPrice).toFixed(2)}</td>
                                </tr>
                              </>)
                          })}
                          <tr>
                            <td align='end' colSpan="7">Sub Total</td>
                            <td align='right'>{Number(selectedData.orderAmount).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td align='end' colSpan="7">Delivery</td>
                            <td align='right'>{Number(selectedData.deliveryCharges).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td align='end' colSpan="7">Discount</td>
                            <td align='right'>{Number(selectedData.discountAmount).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td align='end' colSpan="7">Invoice Total</td>
                            <td align='right'>{Number(selectedData.totalAmount).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td colSpan="8" style={{ width: '50%', height: 130, verticalAlign: 'top' }}><span style={{fontWeight:'bold', fontSize:'15px'}}>{"Terms and Conditions"}</span><br></br>{st.invTermsnCondition ? st.invTermsnCondition : ""}</td>
                            <td colSpan="8" style={{ width: '50%', height: 130, verticalAlign: 'top' }}><span style={{fontWeight:'bold', fontSize:'15px'}}>{"For " + st.businessName}</span></td>
                          </tr>
                          <tr>
                            <td colSpan="8" align='right'>Authorised Signatory</td>
                          </tr>
                          <tr>
                            <td colSpan="8" align='center'>{st.invThankYou ? st.invThankYou : "Thank you for your business with us!"}</td>
                          </tr>
                        </tbody>
                      </table>)
                      :
                      (<table className='table-size table-border'>
                        <tbody key={i}>
                          <tr>
                            <td colSpan="16" align='center'>
                              {st.businessName} <br></br>
                              {st.address1} <br></br>
                              {st.businessState + ', '}{st.businessCountry} <br></br>
                              {st.businessPinCode} <br></br>
                              {st.phone}
                            </td>
                          </tr>
                          <tr style={{ 'backgroundColor': '#D3D3D3' }}>
                            <td colSpan="16" align='center'>GST INVOICE</td>
                          </tr>
                          <tr>
                            <td colSpan="8" style={{ width: '50%' }}>
                              Billing Address <br></br> <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.address !== null && selectedData.deliveryAddress.address} <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.city !== null && selectedData.deliveryAddress.city + ', '}{selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null && selectedData.deliveryAddress.state + ', '} <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.country !== null && selectedData.deliveryAddress.country + ', '}{selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.pinCode !== null && selectedData.deliveryAddress.pinCode} <br></br>

                            </td>
                            <td colSpan="8" style={{ width: '50%' }}>
                              Shipping Address <br></br> <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.address !== null && selectedData.deliveryAddress.address} <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.city !== null && selectedData.deliveryAddress.city + ', '}{selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null && selectedData.deliveryAddress.state + ', '} <br></br>
                              {selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.country !== null && selectedData.deliveryAddress.country + ', '}{selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.pinCode !== null && selectedData.deliveryAddress.pinCode} <br></br>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4" style={{ width: '25%' }}>Invoice Number</td>
                            <td colSpan="4" style={{ width: '25%' }}>{selectedData.orderNum}</td>
                            <td colSpan="4" style={{ width: '25%' }}>Place of Supply</td>
                            <td colSpan="4" style={{ width: '25%' }}>{selectedData.deliveryAddress !== null && selectedData.deliveryAddress.state}</td>
                          </tr>
                          <tr>
                            <td colSpan="4" style={{ width: '25%' }}>Invoice Date</td>
                            <td colSpan="4" style={{ width: '25%' }}>{selectedData.orderDate}</td>
                            <td colSpan="4" style={{ width: '25%' }}>Created By</td>
                            <td colSpan="4" style={{ width: '25%' }}>System Generated</td>
                          </tr>
                          <tr style={{ 'backgroundColor': '#D3D3D3' }}>
                            <th colSpan="2" style={{ width: '5%' }}>#</th>
                            <th colSpan="2" style={{ width: '20%' }}>Product Name</th>
                            <th colSpan="2" style={{ width: '5%' }}>GST</th>
                            <th colSpan="2" style={{ width: '20%' }}>RATE</th>
                            <th colSpan="2" style={{ width: '5%' }}>Qty</th>
                            <th colSpan="2" style={{ width: '20%' }}>Taxable Amt</th>
                            <th colSpan="2" style={{ width: '5%' }}>GST</th>
                            <th colSpan="2" style={{ width: '20%' }}>Total</th>
                          </tr>

                          {selProductList.map((product, index) => {
                            return (
                              <>
                                {taxOption === 'allproduct' ?
                                  <tr key={index}>
                                    <td colSpan="2" style={{ width: '5%' }}>{index = index + 1}</td>
                                    <td colSpan="2" style={{ width: '20%' }}>{product.productName}</td>
                                    <td colSpan="2" align='center' style={{ width: '5%' }}>{totalTax + '%'}</td>
                                    <td colSpan="2" align='center' style={{ width: '20%' }}>{Number(product.price).toFixed(2)}</td>
                                    <td colSpan="2" align='center' style={{ width: '5%' }}>{product.qty !== undefined && product.qty !== null && product.qty}</td>
                                    <td colSpan="2" align='center' style={{ width: '20%' }}>{Number(product.taxbleAmt).toFixed(2)}</td>
                                    <td colSpan="2" align='center' style={{ width: '5%' }}>{Number(product.gstTaxAmt).toFixed(2)}</td>
                                    <td colSpan="2" align='right' style={{ width: '20%' }}>{Number(product.price).toFixed(2)}</td>
                                  </tr> :
                                  <tr key={index}>
                                    <td colSpan="2" style={{ width: '5%' }}>{index = index + 1}</td>
                                    <td colSpan="2" style={{ width: '20%' }}>{product.productName}</td>
                                    <td colSpan="2" align='center' style={{ width: '5%' }}>{product.tax + '%'}</td>
                                    <td colSpan="2" align='center' style={{ width: '20%' }}>{Number(product.price).toFixed(2)}</td>
                                    <td colSpan="2" align='center' style={{ width: '5%' }}>{product.qty !== undefined && product.qty !== null && product.qty}</td>
                                    <td colSpan="2" align='center' style={{ width: '20%' }}>{Number(product.taxbleAmt).toFixed(2)}</td>
                                    <td colSpan="2" align='center' style={{ width: '5%' }}>{Number(product.gstTaxAmt).toFixed(2)}</td>
                                    <td colSpan="2" align='right' style={{ width: '20%' }}>{Number(product.price).toFixed(2)}</td>
                                  </tr>
                                }
                              </>)
                          })}
                          {
                          // selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null && selectedData.deliveryAddress.state.toLowerCase() === businessState.toLowerCase() ?
                          ((!selectedData.deliveryAddress || 
                            !selectedData.deliveryAddress.state || 
                            selectedData.deliveryAddress.state.trim() === '')) || (selectedData.deliveryAddress.state.toLowerCase() === businessState.toLowerCase()) ? 
                            <><tr>
                              <td align='end' colSpan="14" style={{ marginRight: 20, }}>SGST</td>
                              <td align='right'>{Number(snCGST).toFixed(2)}</td>
                            </tr><tr>
                                <td align='end' colSpan="14" style={{ marginRight: 20, }}>CGST</td>
                                <td align='right'>{Number(snCGST).toFixed(2)}</td>
                              </tr></>
                            :
                            <tr>
                              <td align='end' colSpan="14" style={{ marginRight: 20, }}>IGST</td>
                              <td align='right'>{Number(snCGST).toFixed(2)}</td>
                            </tr>
                          }
                          <tr>
                            <td align='end' colSpan="14">Sub Total</td>
                            <td align='right'>{Number(selectedData.orderAmount).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td align='end' colSpan="14">Delivery</td>
                            <td align='right'>{Number(selectedData.deliveryCharges).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td align='end' colSpan="14">Discount</td>
                            <td align='right'>{Number(selectedData.discountAmount).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td align='end' colSpan="14">Invoice Total</td>
                            <td align='right'>{Number(selectedData.totalAmount).toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td colSpan="8" style={{ width: '50%', height: 130, verticalAlign: 'top' }}><span style={{fontWeight:'bold', fontSize:'15px'}}>{"Terms and Conditions"}</span><br></br>{st.invTermsnCondition ? st.invTermsnCondition : ""}</td>
                            <td colSpan="8" style={{ width: '50%', height: 130, verticalAlign: 'top' }}><span style={{fontWeight:'bold', fontSize:'15px'}}>{"For " + st.businessName}</span></td>
                          </tr>
                          <tr>
                            <td colSpan="16" align='right'>Authorised Signatory</td>
                          </tr>
                          <tr>
                            <td colSpan="16" align='center'>{st.invThankYou ? st.invThankYou : "Thank you for your business with us!"}</td>
                          </tr>
                        </tbody>
                      </table>)
                    }
                  </>
                )
                )}
              </Grid>
              :
              // (item.printOption === "3-inch" ?
              <Grid item xs={12} container sx={{ justifyContent: "center" }}>
                <div className='invoice' style={{ width: '330px', height: `${contentHeight}px` }}>
                  <div style={{ width: '100%', backgroundColor: 'pink' }}>
                    {store !== null && store !== undefined && store.map((st, i) => (
                      <>
                        {st.invIsTaxInvoice === false ?
                          (<>
                            <div style={{ textAlign: 'center' }}>
                              <p style={{ fontSize: '12px', fontWeight: 'bold' }}>{st.businessName}</p>
                              <p style={{ fontSize: '10px' }}>{st.address1}</p>
                              <p style={{ fontSize: '10px' }}>{st.businessLocation + ', ' + st.city}</p>
                              <p style={{ fontSize: '10px' }}>{st.businessState + ',' + st.businessCountry}</p>
                              <p style={{ fontSize: '10px' }}>{st.businessPinCode}</p>
                              <p style={{ fontSize: '10px' }}>{st.alternatePhone}</p>
                              <p style={{ fontSize: '10px' }}>{"#" + selectedData.orderNum}{"     "}{selectedData.orderDate !== null && selectedData.orderDate.split('T')[0]}</p>
                            </div>

                            <div style={{ marginBottom: '5px', marginTop: '10px' }} key={'a1'}>
                              <div style={{ borderBottomWidth: 1, borderBottomColor: 'black', borderTopWidth: 1, borderTopColor: 'black', borderTopStyle: 'dotted', borderBottomStyle: 'dotted' }}>
                                <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                  <div style={{ width: '100%', padding: 7, flexDirection: 'row', display: 'flex' }} >

                                    <div style={{ width: '40%' }}>
                                      <p style={{ fontSize: '10px' }}>{'Item'}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p style={{ fontSize: '10px' }}>{'QTY'}</p>
                                    </div>

                                    <div style={{ width: '10%', }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {'Wt'}
                                      </p>
                                    </div>

                                    <div style={{ width: '20%', }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {'Rate'}
                                      </p>
                                    </div>

                                    <div style={{ width: '20%', }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {'AMT'}
                                      </p>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>

                            {selProductList.map((product, index) => {
                              return (
                                <>
                                  <div key={index} style={{ width: '100%', padding: '0px 5px', flexDirection: 'row', display: 'flex' }} >

                                    <div style={{ width: '40%' }}>
                                      <p style={{ fontSize: '10px', wordWrap: 'break-word' }}>{product.productName}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p style={{ fontSize: '10px' }}>{product.qty !== undefined && product.qty !== null && product.qty}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p style={{ fontSize: '10px', wordWrap: 'break-word' }} size={10}>{product.unitWeight !== undefined && product.unitWeight !== null && product.unitWeight}</p>
                                    </div>

                                    <div style={{ width: '20%', }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {Number(product.price).toFixed(2)}
                                      </p>
                                    </div>

                                    <div style={{ width: '20%' }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {Number(product.totalPrice).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                </>)
                            })}


                            <div style={{ borderBottomWidth: 1, borderBottomColor: 'black', borderTopWidth: 1, borderTopColor: 'black', borderTopStyle: 'dotted', borderBottomStyle: 'dotted' }}>
                              <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: '100%', padding: 7, flexDirection: 'row', display: 'flex' }} >

                                  <div style={{ width: '40%' }}>
                                    <p style={{ fontSize: '10px' }}>{'Sub Total'}</p>
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <p style={{ fontSize: '10px' }}>{qty}</p>
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <p >{''}</p>
                                  </div>

                                  <div style={{ width: '20%', }}>
                                    <p>
                                      {''}
                                    </p>
                                  </div>

                                  <div style={{ width: '20%', }}>
                                    <p style={{ fontSize: '10px' }}>
                                      {Number(selectedData.orderAmount).toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <div style={{ width: '100%', padding: '7px', flexDirection: 'row', display: 'flex' }} >

                                <div style={{ width: '40%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '20%' }}>
                                  <p style={{ fontSize: '10px', wordWrap: 'break-word' }}>
                                    {'Delivery Fees'}
                                  </p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px' }}>
                                    {Number(selectedData.deliveryCharges).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <div style={{ width: '100%', padding: '0px 7px 7px', flexDirection: 'row', display: 'flex' }} >

                                <div style={{ width: '40%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px', wordWrap: 'break-word' }}>
                                    {'Discount amount'}
                                  </p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px' }}>
                                    {Number(selectedData.discountAmount).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <div style={{ width: '100%', padding: '0px 7px', flexDirection: 'row', display: 'flex' }} >

                                <div style={{ width: '40%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px' }}>
                                    {'Total'}
                                  </p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px' }}>
                                    {Number(selectedData.orderAmount).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>)
                          :
                          (<>
                            <div style={{ textAlign: 'center' }}>
                              <p style={{ fontSize: '12px', fontWeight: 'bold' }}>{st.businessName}</p>
                              <p style={{ fontSize: '10px' }}>{st.address1}</p>
                              <p style={{ fontSize: '10px' }}>{st.businessLocation + ', ' + st.city}</p>
                              <p style={{ fontSize: '10px' }}>{st.businessState + ',' + st.businessCountry}</p>
                              <p style={{ fontSize: '10px' }}>{st.businessPinCode}</p>
                              <p style={{ fontSize: '10px' }}>{st.alternatePhone}</p>
                              <p style={{ fontSize: '10px' }}>{"#" + selectedData.orderNum}{"     "}{selectedData.orderDate !== null && selectedData.orderDate.split('T')[0]}</p>
                            </div>

                            <div style={{ marginBottom: '5px', marginTop: '10px' }} key={'a1'}>
                              <div style={{ borderBottomWidth: 1, borderBottomColor: 'black', borderTopWidth: 1, borderTopColor: 'black', borderTopStyle: 'dotted', borderBottomStyle: 'dotted' }}>
                                <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                  <div style={{ width: '100%', padding: 7, flexDirection: 'row', display: 'flex' }} >

                                    <div style={{ width: '40%' }}>
                                      <p style={{ fontSize: '10px' }}>{'Item'}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p style={{ fontSize: '10px' }}>{'QTY'}</p>
                                    </div>

                                    <div style={{ width: '10%', }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {'Wt'}
                                      </p>
                                    </div>

                                    <div style={{ width: '20%', }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {'Rate'}
                                      </p>
                                    </div>

                                    <div style={{ width: '20%'}}>
                                      <p style={{ fontSize: '10px' }}>
                                        {'AMT'}
                                      </p>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>

                            {selProductList.map((product, index) => {
                              return (
                                <>
                                  <div key={index} style={{ width: '100%', padding: '0px 5px', flexDirection: 'row', display: 'flex' }} >

                                    <div style={{ width: '40%' }}>
                                      <p style={{ fontSize: '10px', wordWrap: 'break-word' }}>{product.productName}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p style={{ fontSize: '10px' }}>{product.qty !== undefined && product.qty !== null && product.qty}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p style={{ fontSize: '10px', wordWrap: 'break-word' }} size={10}>{product.unitWeight !== undefined && product.unitWeight !== null && product.unitWeight}</p>
                                    </div>

                                    <div style={{ width: '20%', }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {Number(product.price).toFixed(2)}
                                      </p>
                                    </div>

                                    <div style={{ width: '20%' }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {Number(product.totalPrice).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                </>)
                            })}


                            <div style={{ borderBottomWidth: 1, borderBottomColor: 'black', borderTopWidth: 1, borderTopColor: 'black', borderTopStyle: 'dotted', borderBottomStyle: 'dotted' }}>
                              <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: '100%', padding: 7, flexDirection: 'row', display: 'flex' }} >

                                  <div style={{ width: '40%' }}>
                                    <p style={{ fontSize: '10px' }}>{'Sub Total'}</p>
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <p style={{ fontSize: '10px' }}>{qty}</p>
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <p >{''}</p>
                                  </div>

                                  <div style={{ width: '20%', }}>
                                    <p>
                                      {''}
                                    </p>
                                  </div>

                                  <div style={{ width: '20%', }}>
                                    <p style={{ fontSize: '10px' }}>
                                      {Number(selectedData.totalAmount).toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {
                            // selectedData.deliveryAddress !== null && selectedData.deliveryAddress !== undefined && selectedData.deliveryAddress.state !== null && selectedData.deliveryAddress.state.toLowerCase() === businessState.toLowerCase() ?
                            ((!selectedData.deliveryAddress || 
                              !selectedData.deliveryAddress.state || 
                              selectedData.deliveryAddress.state.trim() === '')) || (selectedData.deliveryAddress.state.toLowerCase() === businessState.toLowerCase()) ? 
                              (<>
                                <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                  <div style={{ width: '100%', padding: '0px 7px 7px', flexDirection: 'row', display: 'flex' }} >

                                    <div style={{ width: '40%' }}>
                                      <p>{''}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p>{''}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p>{''}</p>
                                    </div>

                                    <div style={{ width: '20%', }}>
                                      <p style={{ fontSize: '10px', wordWrap: 'break-word' }}>
                                        {'SGST'}
                                      </p>
                                    </div>

                                    <div style={{ width: '20%', }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {Number(snCGST).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                  <div style={{ width: '100%', padding: '0px 7px 7px', flexDirection: 'row', display: 'flex' }} >

                                    <div style={{ width: '40%' }}>
                                      <p>{''}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p>{''}</p>
                                    </div>

                                    <div style={{ width: '10%' }}>
                                      <p>{''}</p>
                                    </div>

                                    <div style={{ width: '20%', }}>
                                      <p style={{ fontSize: '10px', wordWrap: 'break-word' }}>
                                        {'CGST'}
                                      </p>
                                    </div>

                                    <div style={{ width: '20%', }}>
                                      <p style={{ fontSize: '10px' }}>
                                        {Number(snCGST).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                              )
                              :
                              (<div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: '100%', padding: '0px 7px 7px', flexDirection: 'row', display: 'flex' }} >

                                  <div style={{ width: '40%' }}>
                                    <p>{''}</p>
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <p>{''}</p>
                                  </div>

                                  <div style={{ width: '10%' }}>
                                    <p>{''}</p>
                                  </div>

                                  <div style={{ width: '20%', }}>
                                    <p style={{ fontSize: '10px', wordWrap: 'break-word' }}>
                                      {'IGST'}
                                    </p>
                                  </div>

                                  <div style={{ width: '20%', }}>
                                    <p style={{ fontSize: '10px' }}>
                                      {Number(snCGST).toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                              </div>)
                            }

                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <div style={{ width: '100%', padding: '0px 7px 7px', flexDirection: 'row', display: 'flex' }} >

                                <div style={{ width: '40%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px', wordWrap: 'break-word' }}>
                                    {'Discount amount'}
                                  </p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px' }}>
                                    {Number(selectedData.discountAmount).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <div style={{ width: '100%', padding: '7px', flexDirection: 'row', display: 'flex' }} >

                                <div style={{ width: '40%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '20%' }}>
                                  <p style={{ fontSize: '10px', wordWrap: 'break-word' }}>
                                    {'Delivery Fees'}
                                  </p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px' }}>
                                    {Number(selectedData.deliveryCharges).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <div style={{ width: '100%', padding: '0px 7px', flexDirection: 'row', display: 'flex' }} >

                                <div style={{ width: '40%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '10%' }}>
                                  <p>{''}</p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px' }}>
                                    {'Total'}
                                  </p>
                                </div>

                                <div style={{ width: '20%', }}>
                                  <p style={{ fontSize: '10px' }}>
                                    {Number(selectedData.totalAmount).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>)
                        }
                      </>))}
                  </div>
                </div>
              </Grid>
            // : null)
          ))
          }
        </Grid>
      </div>
    </>
  )
}

export default PrintOrder