import React from 'react';
import {DesktopDatePicker} from '@mui/x-date-pickers';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { Controller } from "react-hook-form";
import { makeStyles } from '@mui/styles';

function KeyBoardDatePicker({ id, label,type, control, rules, variant = "standard", fullWidth = true, disabled }) {
 const helperTextStyles = makeStyles(theme => ({
  root: {
    margin: 4,
    color: "red"
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.red
    }
  }
}));

const helperTestClasses = helperTextStyles();
  return (
    <div className="form-field">
      <Controller
        name={id}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => {
          return <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        disabled={disabled ? true : false}
                        value={value}
                        label={label}
                        format="MM/dd/yyyy"
                        InputLabelProps={{ shrink: true, required: true }}
                        onChange={onChange}
                        id={id}
                        renderInput={(params) => <TextField FormHelperTextProps={{ classes: helperTestClasses }} variant={variant}  error={!!error} helperText={error ? error.message : null} fullWidth={fullWidth}  {...params} />}
                      />
                </LocalizationProvider>
        }}
      />
    </div>
  )
}

export default KeyBoardDatePicker
