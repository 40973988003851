
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

import LanguageIcon from '@mui/icons-material/Language';
import { Popover } from "../../../../components/index";
import i18next from 'i18next';

import { IconButton, List, ListItemButton, Typography, ListItemText, Divider } from "@mui/material";
import { languages } from '../../../../Utils/constants';
import { setUserLanguage } from '../../../../redux/reducer/appSlice';



const LanguageSection = (props) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState();
  const userLanguage = useSelector((state) => state.appReducer.userLanguage);
  const [languagePopover, setLanguagePoppover] = useState({
    anchorEl: null,
    data: null,
  });

  useEffect(() => {
    let userLanguage = localStorage.getItem('userLanguage');
    let selectedLanguageIndex = languages.findIndex(el => el.code === userLanguage);
    if (selectedLanguageIndex === -1) {
      selectedLanguageIndex = 0;
    }
    setSelectedIndex(selectedLanguageIndex);
  }, [])

  useEffect(() => {
    let selectedLanguageIndex = languages.findIndex(el => el.code === userLanguage)
    if (selectedLanguageIndex !== -1) {
      setSelectedIndex(selectedLanguageIndex);
    }

  }, [userLanguage])

  const closePopoverHandler = () => {
    setLanguagePoppover({ anchorEl: null, data: null });
  };

  const openPopoverHandler = (event) => {
    setLanguagePoppover({ anchorEl: event.currentTarget, data: null });
  };
  const changeLanguageHandler = (item) => {
    i18next.changeLanguage(item.code)
    dispatch(setUserLanguage(item.code));
    closePopoverHandler();
  }

  return (
    <div className="language-section">
      <IconButton aria-label="language-button" className="language-icon" onClick={openPopoverHandler}>
        <LanguageIcon />
      </IconButton>
      <Popover
        id={"language-popover"}
        open={languagePopover.anchorEl ? true : false}
        anchorEl={languagePopover.anchorEl}
        handleClose={closePopoverHandler}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="language-card">
          <div className="card-header">
            <p className="title">Language</p>
          </div>
          <Divider />
          <div className="card-body">
            <List component="nav">
              {languages.map((item, i) => {
                return (
                  <ListItemButton selected={selectedIndex === i} key={item.code} onClick={() => { changeLanguageHandler(item) }}>
                    <ListItemText primary={<Typography variant="body2">{item.name}</Typography>} />
                  </ListItemButton>
                )
              })}
            </List>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSection;

