export const optionList = {
  frequency: [
    { label: "Day", value: "day" },
    { label: "Month", value: "month" },
    { label: "Year", value: "year" },
  ],
};
export const loginTypeList = {
  type: [
    { label: "Email & Password", value: "email_password" },
    { label: "Mobile OTP", value: "mobile_otp" },
    { label: "Email OTP", value: "email_otp" },
  ],
};
export const printOptionList = {
  type: [
    { label: "A4", value: "A4" },
    { label: "3-inch", value: "3-inch" },
  ],
};
export const taxNameList = {
  type: [
    { label: "GST", value: "GST" },
    { label: "VAT", value: "VAT" },
  ],
};
export const taxTypeList = {
  type: [
    { label: "Percentage", value: "Percentage" },
   // { label: "Fixed", value: "fixed" },
  ],
};
export const taxOptionList = {
  type: [
    { label: "Apply to all the product", value: "allproduct" },
    { label: "To be included to each product", value: "included" },
  ],
};
export const pincodeList = {
  status: [
    { label: "600944", value: "600944" },
    { label: "600028", value: "600028" },
    { label: "605007", value: "605007" },
    { label: "600909", value: "600909" },
    { label: "600095", value: "600095" },
  ],
}
export const statusList = {
  status: [
    { label: "New", value: "New" },
    { label: "Contacted", value: "Contacted" },
    { label: "Working", value: "Working" },
    { label: "Qualified", value: "Qualified" },
    { label: "Unqualified", value: "Unqualified" },
  ],
}
export const languages = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'Tam',
    name: 'தமிழ்',
  }
]

export const productType = {
  type: [
    { label: "Physical Product", value: "Physical Product" },
    // { label: "Digital Product", value: "Digital Product" },
    
  ],
}

export const alertMessages = {
  unableToLoad: "unableToLoad",
  noDataFound: "noDataFound"
}


export const FLOW_TYPE = {
  NEW: "NEW",
  EDIT: "EDIT"
}

export const CRUD_ACTION = {
  DELETE: "DELETE",
  EDIT: "EDIT",
  CREATE:"CREATE",
  VIEW:"VIEW"
}

export const MONTHS =["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

export const DEACTIVATE_ACTIVATE_REVIEW = "DEACTIVATE_ACTIVATE";
export const DEACTIVATE_ACTIVATE_SUB_CATEGORY = "DEACTIVATE_ACTIVATE";
export const DEACTIVATE_ACTIVATE_PRODUCT = "DEACTIVATE_ACTIVATE";
export const DEACTIVATE_ACTIVATE_CATEGORY = "DEACTIVATE_ACTIVATE";
export const DEACTIVATE_ACTIVATE_BANNER = "DEACTIVATE_ACTIVATE";
export const DEACTIVATE_ACTIVATE_USER = "DEACTIVATE_ACTIVATE";
export const DEACTIVATE_ACTIVATE_TESTIMONIAL = "DEACTIVATE_ACTIVATE";
export const DEACTIVATE_ACTIVATE_STATUS = "DEACTIVATE_ACTIVATE";
export const AWS_URL = "https://pinkgrocery-thumbnail.s3.amazonaws.com";
export const TECHNICIAN_ROLE_ID = "ROL00002";


