import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPrService } from '../../axios/axiosInstance';
import { showSnackbar } from '../../redux/reducer/appSlice';
import { isArray } from '../../Utils/commonUtils';

export const fetchLookupOptionsSearch = (columnName, lookupKey, data, dispatch) => {
  return axiosPrService.post(`/master/search/${[columnName]}`, data)
    .then(res => {
      let responseData = []
      if (res.status === 200 && isArray(res.data)) {
        responseData = res.data.map(el => {
          return {
            label: el[lookupKey],
            value: el[columnName + "Id"],
          }
        })
        return responseData;
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to load lookup option" }));
        return responseData;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return [];
      }
    })
};


export const fetchProjectList = createAsyncThunk('/setProjectList', async ({ data, params }, { dispatch }) => {
  return await axiosPrService.post('/master/search/order', data, { params }, { dispatch })
    .then(res => {
      if (res.status === 200) {
       // console.log("data ::: " + JSON.stringify(res.data));
        return res.data
      } else {
        throw new Error("Service unavailable")
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
      }
    });
}
)

export const fetchOrderTableConfigOverview =  createAsyncThunk('/fetchOrderTableConfigOverview', async ({ params }, { dispatch }) => {
  let result = [];
  // return await axiosPrService.get('/configuration/order', { params }, { dispatch })
  //   .then(res => {
  //     if (res.status === 200 && isArray(res.data)) {
  //       let result = res.data[0].fields;
  //       console.log("colmns " + JSON.stringify(result));
        var colJson =[{"fieldName":"orderNum","displayName":"Order Num","isDisplayField":true,"isSortField":true},{"fieldName":"totalAmount","displayName":"Total Amount","isDisplayField":true,"isSortField":true},{"fieldName":"deliveryAddress","displayName":"Location","isDisplayField":true,"isSortField":true},{"fieldName":"status","displayName":"Status","isDisplayField":true,"isSortField":true},{"fieldName":"isPaid","displayName":"Paid","isDisplayField":true,"isSortField":true},{"fieldName":"orderDate","displayName":"Order Date","isDisplayField":true,"isSortField":true},{"fieldName":"orderId","displayName":"Action","isDisplayField":true,"isSortField":true},{"fieldName":"view","displayName":"View","isDisplayField":true,"isSortField":true}];
        return colJson;
    //   } else {
    //     dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Service unavailable" }));
    //     return result;
    //   }
    // }).catch(e => {
    //   if (e.status !== 401) {
    //     dispatch(showSnackbar({ type: "error", message: e.message }));
    //     return result;
    //   }
    // });
});

const initialState = {
  projectList: {
    data: null,
    loading: false,
    error: null
  },
  columnsOriginalResponse: [],
}

const overviewSlice = createSlice({
  name: "overviewSlice",
  initialState: initialState,
  reducers: {
    // logout(state, action) {
    //   state = initialState
    //   window.localStorage.removeItem('authTokens');
    // }
  },
  extraReducers: {
    [fetchProjectList.pending]: (state, action) => {
      state.projectList.loading = true
    },
    [fetchProjectList.fulfilled]: (state, action) => {
      state.projectList.loading = false;
      state.projectList.data = action.payload || [];
    },
    [fetchProjectList.rejected]: (state, action) => {
      state.projectList.loading = false;
      state.projectList.data = null;
      state.projectList.error = true;
    },
     [fetchOrderTableConfigOverview.pending]: (state, action) => {
      state.loading = true
    },
    [fetchOrderTableConfigOverview.fulfilled]: (state, action) => {
      state.loading = false;
      state.columnsOriginalResponse = action.payload
    },
    [fetchOrderTableConfigOverview.rejected]: (state, action) => {
      state.loading = false;
      state.columnsOriginalResponse = [];
      state.columnsError = true;
    },
  }
});
export const { logout } = overviewSlice.actions;

export default overviewSlice.reducer;