import PrintOrder from "../PrintOrder/PrintOrder";



export default function SubLayout() {

    return (
        <PrintOrder />
    )

}