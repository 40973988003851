import React, { Suspense, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import dayjs from 'dayjs';
import { LogoSection, ProfileSection, PanelDropdown, NotificationSection, LanguageSection } from './index';
import './Header.scss';

export default function Header(props) {
  const { matchUpMd, toggleLeftDrawerHandler } = props;
  const [time, setTime] = useState(Date.now());

  // useEffect(() => {
  //   const interval = setInterval(() => setTime(Date.now()), 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <div>
      <Toolbar className="header-wrapper">
        <Box sx={{ width: matchUpMd ? "228px" : "auto" }}>
          <LogoSection
            toggleLeftDrawerHandler={toggleLeftDrawerHandler}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: matchUpMd ? "calc(100% - 228px)" : "100%", alignItems: "center" }}>
          <PanelDropdown />
          <Box className="right-side-section" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>{dayjs(time).format('MMM DD YYYY')}</Box>
            <LanguageSection />
          {/*  <Suspense fallback="loading">
              <NotificationSection />
            </Suspense>*/}
            <Suspense fallback="loading">
              <ProfileSection />
            </Suspense>
          </Box>
        </Box>
      </Toolbar>
      <Box sx={{ display: { xs: 'block', md: 'none', textAlign: "right", paddingBottom: "5px", paddingRight: "30px" } }}>{dayjs(time).format('MMM DD YYYY, H:m:ss')}</Box>
    </div>
  )
}