import { axiosPrService } from '../../../axios/axiosInstance';
import { showSnackbar } from '../../../redux/reducer/appSlice'


export const fetchDeliveryCharge = (data, dispatch) => {
  return axiosPrService.post(`/master/search/delivery`, data)
    .then(res => {
       let result = ""
      if (res.status === 200) {
         return res.data;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return false;
      }
    })
};

export const validateDiscount = ({discountId,data, dispatch}) => {
  return axiosPrService.post(`/order/discount/${discountId}/validate`, data)
    .then(res => {
       let result = ""
      if (res.status === 200) {
         return res.data;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return false;
      }
    })
};

export const fetchProductByOrderId = (data, dispatch) => {
  return axiosPrService.post(`/master/search/orderdetails?perPage=500`, data)
    .then(res => {
      if (res.status === 200) {
        //dispatch(showSnackbar({ type: "success", message: `Order  ${isActive ? "Activated" : "Deactivated"} Successfully ` }));
        return res.data
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : `Unable to fetch product data ` }));
        return res.data
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return false;
      }
    })
};

export const createOrder = ({ data, dispatch }) => {
  return axiosPrService.post('/order/create', data)
    .then(res => {
      let result = ""
      if (res.status === 200) {
        // if (res.data.success === "Order placed Successfully" ) {
          result = res.data;//res.data;
          console.log("Create order response>>>>>>>",result);
          return result
        // } else {
        //   dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to create order" }));
        //   return result
        // }
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to create order" }));
        return result
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}

export const getCustomerFromBusUserById = ({ data, dispatch }) => {
  return axiosPrService.post('/master/search/bususer', data)
    .then(res => {
      let result = ""
      if (res.status === 200) {
         return res.data[0];
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}

export const getCustomerById = ({ data, dispatch }) => {
  return axiosPrService.post('/master/search/customer', data)
    .then(res => {
      let result = "not-found"
      if (res.status === 200) {
         return res.data[0];
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}

export const getDiscountById = ({ data, dispatch }) => {
  return axiosPrService.post('/master/search/discount', data)
    .then(res => {
      let result = ""
      if (res.status === 200) {
         return res.data[0];
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}

export const getTechnicianById = ({ data, dispatch }) => {
  return axiosPrService.post('/master/search/user', data)
    .then(res => {
      let result = ""
      if (res.status === 200) {
         return res.data[0];
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}

export const updateOrder = ({ data, orderId, dispatch }) => {

  return axiosPrService.post(`order/update/${orderId}`, data)
    .then(res => {
      let result = ""
      if (res.status === 200) {
        if (res.data.success) {
          result = "success";
          return result
        } else {
          console.log('else')
          dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to update order" }));
          return result
        }
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to update order" }));
        return result
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}