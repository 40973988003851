import React from "react";
import { Box, Typography } from "@mui/material";
import { Loader } from '../index';
import "./EngergyCard.scss";
import { formatToDecimal } from '../../Utils/commonUtils';

const EnergyCard = (props) => {
  const {
    // content
    title,
    icon,
    itemList,
    helperText,

    // for css
    itemIndex,
    total,

    // config
    matchUpLg,
    loading
  } = props;

  let cornerUp = "";
  let firstItem = "";
  let lastItem = "";
  let leftDrawerOpened = "";

  if (matchUpLg && (itemIndex === 0 || itemIndex === total - 1)) {
    cornerUp = "corner-up";
  }
  if (itemIndex === 0) {
    firstItem = "first-item";
  }

  if (itemIndex === total - 1) {
    firstItem = "last-item";
  }

  if (leftDrawerOpened) {
    leftDrawerOpened = "left-drawer-enabled";
  }

  return (
    <Box
      component="div"
      id={`c${itemIndex + 1}`}
      className={`card-wrapper energy-card ${cornerUp} ${firstItem} ${lastItem} ${leftDrawerOpened}`}
    >
      {loading && <Loader color={"#fff"} />}
      {!loading &&
        <>
          <div className="card-header">
            <Typography className={"title"}> {title} </Typography>
          </div>
          <div className="card-content">
            {icon && <div className={`icons ${icon}`}></div>}
            {itemList && (
              <div className="item-list">
                {itemList.map((el, i) => {
                  return (
                    <div className="item" key={el.label + i}>
                      <div className={`value`}>
                        {formatToDecimal(el.value)}
                        {el.suffix && (
                          <span className="value-suffix">{el.suffix}</span>
                        )}
                      </div>
                      <span className="text">{el.label}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      }
      {helperText && <div className="helper-text">{helperText}</div>}
    </Box>
  );
};

export default EnergyCard;
