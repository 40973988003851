import React from 'react';

import { default as SelectMUI } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Controller } from "react-hook-form";

function Select({ id, label, control, rules, variant = "standard", fullWidth = true, disabled, options, placeholder, multiple }) {
  return (
    <div className="form-field">
      <Controller
        name={id}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => {

          return (<FormControl variant={variant} className="form-control" fullWidth={fullWidth} error={error ? error : null}>
            <InputLabel id={id}>{label}</InputLabel>
            <SelectMUI
              className="select-input-field"
              id={id}
              value={value}
              onChange={onChange}
              IconComponent={KeyboardArrowDownIcon}
              variant={variant}
              fullWidth={fullWidth}
              error={!!error}
              // helperText={error ? error.message : null}
              multiple={multiple ? multiple : undefined}
            >
              {options && options.length > 0 && options.map((el, i) => {
                return (
                  <MenuItem
                    key={el.value + i}
                    value={el.value}
                  >
                    {el.label}
                  </MenuItem>)
              })}
            </SelectMUI>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
          )
        }}
      />
    </div>
  )
}

export default Select
