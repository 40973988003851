import { axiosPrService } from '../../../axios/axiosInstance';
import { showSnackbar } from '../../../redux/reducer/appSlice'
import { isArray } from '../../../Utils/commonUtils';


export const fetchTax = ( dispatch) => {
  return axiosPrService.post(`/master/search/tax`, {})
    .then(res => {
      let responseData = []
      if (res.status === 200 && isArray(res.data)) {
        responseData = res.data;
        return responseData;
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to load lookup option" }));
        return responseData;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: 'e.message' }));
        return [];
      }
    })
};

export const createTax = ({ data, dispatch }) => {
  return axiosPrService.post(`master/tax`, data)
    .then(res => {
      let result = ""
      if (res.status === 200) {
          if (res.data.uniqueId) {
            result = "taxSuccess";
            return result
          } else {
            console.log('else')
            dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to create tax" }));
            return result
          }
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to create tax" }));
        return result
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}

export const updateTax = ({ data, taxId, dispatch }) => {
  console.log("appp sid :: " + taxId);
  return axiosPrService.post(`master/tax/${taxId}/update`, data)
    .then(res => {
      let result = ""
      if (res.status === 200) {
          if (res.data === "taxSuccess") {
            result = res.data;
            return result
          } else {
            console.log('else')
            dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to update tax" }));
            return result
          }
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to update tax" }));
        return result
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}