import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPrService } from '../../axios/axiosInstance';

export const fetchSCLookupList = createAsyncThunk('/fetchSCLookupList', async (params, { dispatch }) => {
 
  return await axiosPrService.post(`/master/search/${params.masterName}`, params.data)
    .then(res => {
      let data = { label: params.masterName, list: [] }
      if (res.status === 200 && res && res.data) {
        data.list = res.data
        return data;
      } else {
        return data
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSCSnackbar({ type: "error", message: e.message }));
      }
    })
})

const snackbarSCDataInitialState = Object.freeze({
  show: false,
  message: "",
  type: "",
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  autoHideDuration: 4000,
});


const initialState = {
  leftDrawerOpened: false,
  snackbarSCData: snackbarSCDataInitialState,
  projectPanel: null,
  // userLanguage is tracked here only to update localState in overview and dashboard page
  userLanguage: null,

  sclookup: {},
  lookupLoading: false,
  lookupError: null,
};

export const appSCSlice = createSlice({
  name: 'appSCReducer',
  initialState,
  reducers: {
    toggleLeftDrawer(state) {
      state.leftDrawerOpened = !state.leftDrawerOpened;
    },
    showSCSnackbar(state, action) {
      const { payload } = action;
      state.snackbarSCData.show = true
      state.snackbarSCData.message = payload.message;
      state.snackbarSCData.type = payload.type;
      if (payload.anchorOrigin) {
        state.snackbarSCData.anchorOrigin = payload?.origin;
      }
      if (payload.autoHideDuration) {
        state.snackbarSCData.autoHideDuration = payload.autoHideDuration;
      }
    },
    clearSCSnackbar(state) {
      state.snackbarSCData.show = false
    },
    setProjectPanel(state, action) {
      state.projectPanel = action.payload
    },
    setUserLanguage(state, action) {
      state.userLanguage = action.payload
    }
  },
  extraReducers: {
    [fetchSCLookupList.pending]: (state, action) => {
      state.lookupLoading = true
    },
    [fetchSCLookupList.fulfilled]: (state, action) => {
      const columnName = action.payload.label;
      const formatLabel = { label: "", value: "" };
       if (columnName === "subcategory") {
        formatLabel.label = "subCategoryName"
        formatLabel.value = "subCategoryId"
      }

      let data = action.payload.list.map(el => {
        return {
          ...el,
          label: el[formatLabel.label],
          value: el[formatLabel.value]
        }
      })
      state.lookupLoading = false;
      state.sclookup[action.payload.label] = data
    },
    [fetchSCLookupList.rejected]: (state, action) => {
      state.lookupLoading = false;
      state.lookupError = true;
    },
  }
});

export const { toggleLeftDrawer, showSCSnackbar, clearSCSnackbar, setProjectPanel, setUserLanguage } = appSCSlice.actions;

export default appSCSlice.reducer;