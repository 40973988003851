import axios from 'axios';
import { baseURL } from '../config/config';

const authTokens = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null;

export const axiosPrService = axios.create({
  baseURL,
  timeout: 12000,
  headers: {
    Authorization: `Bearer ${authTokens?.accessToken}`
  }
});

export const axiosPbService = axios.create({
  baseURL,
  timeout: 12000,
});


