import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import Box from "@mui/material/Box";
import { Drawer } from "@mui/material";
import MenuList from "./MenuList/MenuList";
import { Logo } from '../../../components/index';


export default function SideDrawer(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { leftDrawerOpened, drawerWidth, toggleLeftDrawerHandler, matchUpMd } = props;

  const menuList = [
    {
      id: "overview",
      title: t('Overview'),
      url: "/overview",
      icon: "home-menu-icon",
      isLast: false,
      lastChaild: false
    },
    {
      id: "settings",
      title: t('Manage'),
      url: "/settings",
      icon: "manage-user-menu-icon",
      isLast: true,
      lastChaild: false
    },
    {
      id: "category",
      title: t('Category'),
      url: "/manage-category",
      icon: "category-icon",
      isLast: false,
      lastChaild: false
    },
    {
      id: "sub-category",
      title: t('Sub Category'),
      url: "/manage-sub-category",
      icon: "subcategory-icon",
      isLast: false,
      lastChaild: false
    },
    {
      id: "product",
      title: t('Product'),
      url: "/manage-product",
      icon: "product-icon",
      isLast: true,
      lastChaild: false
    },
    {
      id: "order",
      title: t('Order'),
      url: "/manage-order",
      icon: "order-list",
      isLast: false,
      lastChaild: false
    },
    {
      id: "create-order",
      title: t('Create Order'),
      url: "/create-order",
      icon: "order-icon",
      isLast: false,
      lastChaild: false
    },
    {
      id: "store",
      title: t('Store'),
      url: "/edit-store",
      icon: "store-icon",
      isLast: false,
      lastChaild: false
    },
    {
      id: "report",
      title: t('Reports'),
      url: "/report",
      icon: "reports-menu-icon",
      isLast: true,
      lastChaild: false
    },
    {
      id: "theme",
      title: t('Theme'),
      url: "/modifyTheme",
      icon: "theme-icon",
      isLast: false,
      lastChaild: false
    },
    {
      id: "help",
      title: t('Help Center'),
      url: "/help",
      icon: "help-center-icon",
      isLast: false,
      lastChaild: true
    },
  ];


  return (
    <Box
      component="nav"
      sx={{
        display: "flex",
        height: leftDrawerOpened ? "100vh" : "auto",
      }}
    >
      <Drawer
        className="side-drawer-wrapper "
        variant={matchUpMd ? "persistent" : "temporary"}
        open={leftDrawerOpened}
        onClose={toggleLeftDrawerHandler}
        container={document.querySelector("document body")}
        anchor="left"
        sx={{
          "& .MuiDrawer-paper": {
            background: theme.palette.background.primary,
            color: theme.palette.text.primary,
            borderRight: "none",
            top: {
              sm: "0",
              md: "64px",
            },
            padding: "15px",
            width: drawerWidth,
          },
          width: drawerWidth,
        }}
        ModalProps={{ keepMounted: true }}
      >
        <>
          <Box sx={{ display: { xs: "flex", md: "none" } }} >
            <Link
              to={`/overview`}
            >
              <Logo />
            </Link>
          </Box>
          <MenuList toggleLeftDrawerHandler={toggleLeftDrawerHandler} menuList={menuList} />
        </>
      </Drawer>
    </Box>
  );
}
