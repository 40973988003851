import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Tooltip from '@mui/material/Tooltip';
import { axiosPrService } from '../../../axios/axiosInstance';
import { showSnackbar } from '../../../redux/reducer/appSlice';
import { isArray } from '../../../Utils/commonUtils';
import { FLOW_TYPE } from '../../../Utils/constants';

export const fetchProductList = createAsyncThunk('/fetchManageProductList', async ({ data, params }, { dispatch }) => {
  params.page = params.page + 1;
  let result = { total: 0, data: [] } 
  return await axiosPrService.post('/master/search/product', data, { params }, { dispatch })
    .then(res => {
      console.log("checking product+++++++", res);
      if (res.status === 200 && isArray(res.data)) {
        console.log(res.data);
        // for (var i = 0; i < res.data.length; i++) {
        //       res.data[i].productName =  <Tooltip title={res.data[i].productName}><span>
        //            { res.data[i].productName.length > 25 ? `${res.data[i].productName.substring(0, 25)}...` : res.data[i].productName }
        //            </span>
        //           </Tooltip> ;
        //   }
        result = { total: Number(res.headers['x-total-records']), data: res.data }
        return result;
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Service unavailable" }));
        return result;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return result;
      }
    });
});

export const fetchProductListTableColumnConfig = createAsyncThunk('/fetchProductTableConfig', async ({ params }, { dispatch }) => {
  let result = [];
  return await axiosPrService.get('/configuration/product', { params }, { dispatch })
    .then(res => {
      if (res.status === 200 && isArray(res.data)) {
        let result = res.data[0].fields;
        console.log("configuration",result);
        return result
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Service unavailable" }));
        return result;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: e.message }));
        return result;
      }
    });
});



const initialState = {
  // table 
  data: [],
  loading: false,
  error: null,

  // columns 
  columnsOriginalResponse: [],
  columnsError: null,

  page: 0,
  total: 0,
  rowsPerPage: 10,
  sortBy: "",
  sortKey: "",
  appliedFilterList: [],

  flow: FLOW_TYPE.NEW,
  selectedProductData: null,
};

export const manageProductTableReducer = createSlice({
  name: 'manageProductTableReducer',
  initialState,
  reducers: {
    updateTableState(state, { payload }) {
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
  },
  extraReducers: {
    [fetchProductList.pending]: (state, action) => {
      state.loading = true
    },
    [fetchProductList.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload?.data || [];
      state.total = action.payload?.total || 0;
    },
    [fetchProductList.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    },

    [fetchProductListTableColumnConfig.pending]: (state, action) => {
      state.loading = true
    },
    [fetchProductListTableColumnConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.columnsOriginalResponse = action.payload
    },
    [fetchProductListTableColumnConfig.rejected]: (state, action) => {
      state.loading = false;
      state.columnsOriginalResponse = [];
      state.columnsError = true;
    },
  }
});

export const { updateTableState } = manageProductTableReducer.actions;

export default manageProductTableReducer.reducer;
