import { useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import SubRoutes from './SubRoutes';

const PageNotFound = () => {
    return <div>Not found</div>
}
export default function AppRoutes({ user }) {
    return useRoutes(
        [
            MainRoutes(user),
            SubRoutes(user),
            AuthRoutes(),
            { path: '*', element: <PageNotFound /> }
        ]
    );
}
