import { axiosPrService } from '../../../axios/axiosInstance';
import { showSnackbar } from '../../../redux/reducer/appSlice'
import { isArray } from '../../../Utils/commonUtils';

export const signedUrl = ({ data, dispatch }) => {
  return axiosPrService.post('/file/signedurl', data)
    .then(res => {
      let result = ""
      if (res.status === 200) {
        //if (res.data.data === "signedUrl") {
          result = res.data
          return result
        //} else {
         // dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to create banner" }));
          //return result
        //}
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to create banner" }));
        return result
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}

export const fetchStore = ( dispatch) => {
  return axiosPrService.post(`/master/search/store`, {})
    .then(res => {
      let responseData = []
      if (res.status === 200 && isArray(res.data)) {
        responseData = res.data;
        return responseData;
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to load lookup option" }));
        return responseData;
      }
    }).catch(e => {
      if (e.status !== 401) {
        dispatch(showSnackbar({ type: "error", message: 'e.message' }));
        return [];
      }
    })
};

export const updateStore = ({ data, STORE_ID, dispatch }) => {
  console.log("appp sid :: " + STORE_ID);
  return axiosPrService.post(`master/store/${STORE_ID}/update`, data)
    .then(res => {
      let result = ""
      if (res.status === 200) {
          if (res.data === "storeSuccess") {
            result = res.data;
            return result
          } else {
            console.log('else')
            dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to update store" }));
            return result
          }
      } else {
        dispatch(showSnackbar({ type: "error", message: res.data ? res.data : "Unable to update store" }));
        return result
      }
    })
    .catch(e => {
      dispatch(showSnackbar({ type: "error", message: e.message }));
    })
}